import http from "./http";
export default (receiptId) => ({
  async index(filters) {
    return http
      .index("receipts", filters)
      .then((json) => ({ receipts: json }))
      .catch(async (e) => ({ receipts: null, error: await e }));
  },
  async get() {
    return http
      .get("receipts", receiptId)
      .then((json) => ({ receipt: json }))
      .catch(async (e) => ({ receipt: null, error: await e }));
  },
  async create(data) {
    return http
      .post("receipts", data)
      .then((json) => ({ receipt: json }))
      .catch(async (e) => ({ receipt: null, error: await e }));
  },
  async update(data) {
    return http
      .put("receipts", receiptId, data)
      .then((json) => ({ receipt: json }))
      .catch(async (e) => ({ receipt: null, error: await e }));
  },
  async delete() {
    return http
      .delete("receipts", receiptId)
      .then((json) => ({ receipt: json }))
      .catch(async (e) => ({ receipt: null, error: await e }));
  },
  Schools: (schoolId) => ({
    async index(filters) {
      return http
        .index(`receipts/${receiptId}/schools`, filters)
        .then((json) => ({ schools: json }))
        .catch(async (e) => ({ schools: null, error: await e }));
    },
    async get() {
      return http
        .get(`receipts/${receiptId}/schools`, schoolId)
        .then((json) => ({ school: json }))
        .catch(async (e) => ({ school: null, error: await e }));
    },
    async create(data) {
      return http
        .post(`receipts/${receiptId}/schools/${schoolId}`, data)
        .then((json) => ({ school: json }))
        .catch(async (e) => ({ school: null, error: await e }));
    },
    async update(data) {
      return http
        .put(`receipts/${receiptId}/schools`, schoolId, data)
        .then((json) => ({ school: json }))
        .catch(async (e) => ({ school: null, error: await e }));
    },
    async delete() {
      return http
        .delete(`receipts/${receiptId}/schools`, schoolId)
        .then((json) => ({ school: json }))
        .catch(async (e) => ({ school: null, error: await e }));
    },
    Products: (productId) => ({
      async index(filters) {
        return http
          .index(`receipts/${receiptId}/schools/${schoolId}/products`, filters)
          .then((json) => ({ products: json }))
          .catch(async (e) => ({ products: null, error: await e }));
      },
      async get() {
        return http
          .get(`receipts/${receiptId}/schools/${schoolId}/products`, productId)
          .then((json) => ({ product: json }))
          .catch(async (e) => ({ product: null, error: await e }));
      },
      async create(data) {
        return http
          .post(`receipts/${receiptId}/schools/${schoolId}/products`, data)
          .then((json) => ({ product: json }))
          .catch(async (e) => ({ product: null, error: await e }));
      },
      async update(data) {
        return http
          .put(`receipts/${receiptId}/schools/${schoolId}/products`, productId, data)
          .then((json) => ({ product: json }))
          .catch(async (e) => ({ product: null, error: await e }));
      },
      async delete() {
        return http
          .delete(`receipts/${receiptId}/schools/${schoolId}/products`, productId)
          .then((json) => ({ product: json }))
          .catch(async (e) => ({ product: null, error: await e }));
      },
    }),
  }),
});
