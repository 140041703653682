<template>
  <el-dialog :title="ModalTitle" v-model="shouldShow">
    <form>
      <el-select
        v-model="receipt_.public_sell_id"
        size="medium"
        filterable
        remote
        value-key="uid"
        :remote-method="fetchPublicSells"
      >
        <template #prefix>Licitação: </template>
        <el-option
          v-for="item in PublicSells"
          :key="item.uid"
          :label="item.name"
          :value="item.uid"
        >
        </el-option>
      </el-select>

      <el-select
        v-model="receipt_.order_id"
        size="medium"
        multiple
        filterable
        remote
        value-key="uid"
        :remote-method="fetchOrders"
      >
        <template #prefix>Pedidos: </template>
        <el-option
          v-for="order in Orders"
          :key="order?.uid"
          :label="getOrderLabel(order)"
          :value="order.uid"
        >
        </el-option>
      </el-select>
      <el-select
        v-model="receipt_.route_id"
        size="medium"
        filterable
        remote
        value-key="uid"
        :remote-method="fetchRoutes"
      >
        <template #prefix>Rota: </template>
        <el-option
          v-for="route in Routes"
          :key="route?.uid"
          :label="route.name"
          :value="route.uid"
        >
        </el-option>
      </el-select>
      <el-date-picker
        v-model="receipt_.emitted_at"
        placeholder="Data de emissão"
        type="date"
        size="medium"
        format="DD/MM/YYYY HH:mm:ss"
      >
      </el-date-picker>
    </form>
    <template #footer>
      <el-row justify="space-between" type="flex">
        <el-button
          type="text"
          class="button"
          @click="shouldShow = false"
          :loading="isLoadingSave"
          :disabled="isLoadingSave"
          >Cancelar</el-button
        >
        <el-button type="text" class="button" @click="save">Salvar</el-button>
      </el-row>
    </template>
  </el-dialog>
</template>
<script>
import ReceiptService from "../../services/receipts";
import OrderService from "../../services/orders";
import PublicSellService from "../../services/public_sells";
import RouteService from "../../services/routes";
import { notifyError, notifySuccess } from "../../utils/notifiers";
import { hasOnlyDigits } from "../../utils/functions";

export default {
  props: ["receipt", "showModal"],
  emits: ["close-modal", "should-update"],
  data() {
    return {
      receipt_: this.category || {},
      eventDate: [],
      isLoadingSave: false,
      public_sells: null,
      orders: null,
      routes: null,
    };
  },
  watch: {
    receipt(v) {
      if (v) this.receipt_ = { ...v };

      if (v.orders) {
        this.orders = { data: v.orders };

        this.receipt_.order_id = v.orders.map((order) => order.uid);
      }

      if (v.route) {
        this.routes = [v.route];

        this.receipt_.route_id = v.route.uid;
      }

      if (v.public_sell) {
        this.public_sells = [v.public_sell];

        this.receipt_.public_sell_id = v.public_sell.uid;
      }
    },
  },
  computed: {
    ModalTitle() {
      return !this.isNew ? "Editar Recibo" : "Cadastrar Recibo";
    },
    PublicSells() {
      return this.public_sells || [];
    },
    Orders() {
      return this.orders?.data || [];
    },
    Routes() {
      return this.routes || [];
    },
    shouldShow: {
      get() {
        return this.showModal;
      },
      set() {
        this.$emit("close-modal");
      },
    },
    isNew() {
      return !("uid" in (this.receipt || {}));
    },
  },
  methods: {
    async fetchRoutes(searchQuery) {
      const { routes } = await RouteService().index({
        limit: 25,
        searchQuery,
        type: "public",
        pagination: 1,
      });

      if (routes) this.routes = routes;
    },
    async fetchPublicSells(searchQuery) {
      const { publicSells } = await PublicSellService().index({ searchQuery });

      if (publicSells) this.public_sells = publicSells;
    },
    async fetchOrders(searchName) {
      const { orders } = await OrderService().index({
        searchName,
        public_sell_id: this.receipt_.public_sell_id,
        searchCpfCnpj: hasOnlyDigits(searchName) ? searchName : null,
        limit: 25,
        pagination: 1,
      });

      if (orders) this.orders = orders;
    },
    save() {
      this.isLoadingSave = true;

      return this.isNew ? this.createReceipt() : this.updateReceipt();
    },
    async createReceipt() {
      this.isLoadingSave = true;

      const { receipt, error } = await ReceiptService().create(this.receipt_);

      if (receipt) {
        notifySuccess(receipt.message);
        this.$emit("should-update");
        this.$emit("close-modal");
      } else notifyError(error.message);

      this.isLoadingSave = false;
    },
    async updateReceipt() {
      this.isLoadingSave = true;

      const { receipt, error } = await ReceiptService(this.receipt_.uid).update(
        this.receipt_
      );

      if (receipt) {
        notifySuccess(receipt.message);
        this.$emit("should-update");
        this.$emit("close-modal");
      } else notifyError(error.message);

      this.isLoadingSave = false;
    },
    getOrderLabel(order) {
      if (order) return `#${order.code} - ${order?.client?.name}`;

      return "Pedido S/N";
    },
  },
  name: "ReceiptModal",
};
</script>
<style>
.el-button {
  max-height: 36px !important;
  margin-top: auto !important;
  margin-bottom: auto !important;
}
.mt-1 {
  margin-top: 4px;
}
.stretch-width {
  width: 100% !important;
}
</style>
